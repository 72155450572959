import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import {
  CREATE_SEGMENTED_ZONE,
  DELETE_SEGMENTED_ZONE,
  FILIAL_LAST_INDEX,
  INIT_SEGMENTED_ZONES_PAGE,
  SEGMENTED_ZONES_PAGE_FILTERS,
  UPDATE_SEGMENTED_ZONE,
} from '../data/gql';
import { TPaginator } from '@core/domain/types';
import {
  ICreateSegmentedZone,
  InitSegmentedZonesResponse,
  IUpdateSegmentedZone,
} from '../data/interfaces';
import { TSegmentedZonesFilter } from '../data/types';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly apolloClient = inject(Apollo);

  segmentedZonesInit(pagination: TPaginator) {
    return this.apolloClient
      .query<InitSegmentedZonesResponse>({
        query: INIT_SEGMENTED_ZONES_PAGE,
        variables: {
          pagination: {
            limit: +pagination.pageSize,
            page: pagination.page,
          },
          filters: {},
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data));
  }

  filterSegmentedZones(
    pagination: TPaginator,
    filters: Partial<TSegmentedZonesFilter>,
  ) {
    return this.apolloClient
      .query<InitSegmentedZonesResponse>({
        query: SEGMENTED_ZONES_PAGE_FILTERS,
        variables: {
          pagination: {
            limit: +pagination.pageSize,
            page: pagination.page,
          },
          filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data));
  }

  getZoneLastIndex(zoneId: string) {
    return this.apolloClient
      .query<{ lastSegmentedZoneIndex: string }>({
        query: FILIAL_LAST_INDEX,
        variables: {
          zoneId,
        },
      })
      .pipe(map((res) => res.data.lastSegmentedZoneIndex));
  }

  createSegmentedZone(newSegmentedZone: ICreateSegmentedZone) {
    return this.apolloClient
      .mutate<{ createSegmentedZone: string }>({
        mutation: CREATE_SEGMENTED_ZONE,
        variables: {
          newSegmentedZone,
        },
      })
      .pipe(map((res) => res.data.createSegmentedZone));
  }

  updateSegmentedZones(segmentedZone: IUpdateSegmentedZone) {
    return this.apolloClient
      .mutate<{ updateSegmentedZone: string }>({
        mutation: UPDATE_SEGMENTED_ZONE,
        variables: {
          segmentedZone,
        },
      })
      .pipe(map((res) => res.data.updateSegmentedZone));
  }

  removeSegmentedZone(segmentedZoneId: string) {
    return this.apolloClient
      .mutate<{ deleteSegmentedZone: string }>({
        mutation: DELETE_SEGMENTED_ZONE,
        variables: {
          segmentedZoneId,
        },
      })
      .pipe(map((res) => res.data.deleteSegmentedZone));
  }
}
