<div class="flex gap-3 items-center">
  @for (page of pages(); track page; let i = $index) {
    @if (i > 0) {
      <span class="h-3 w-2">
        <svg-icon icon="chevron-right" />
      </span>
    }
    <a
      class="last:font-bold"
      [ngClass]="{
        'hover:cursor-pointer hover:underline': !!page.path,
      }"
      [routerLink]="page.path"
    >
      {{ page.label }}
    </a>
  }
</div>
