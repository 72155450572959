import { TSearchAccountContract } from '../types';

export class AccountContractBuilder {
  constructor(
    readonly account_contract: string,
    readonly scheduling_name: string,
    readonly scheduling_phone: string,
    readonly technical_referral: string,
    readonly data_policy_accepted: boolean,
    readonly process_accepted: boolean,
    readonly will_attend: boolean,
    readonly attendant_name: string,
    readonly attendant_phone: string,
    readonly visitor_data_policy_accepted: boolean,
  ) {}

  static fromJson(account: Partial<TSearchAccountContract>) {
    const willAttend = account?.will_attend === 'YES';
    const schedulingName = account?.scheduling_name || '';
    const schedulingPhone = account?.scheduling_phone || '';

    return new AccountContractBuilder(
      (account?.account_contract || '').padStart(12, '0'),
      schedulingName,
      schedulingPhone,
      account?.technical_referral || '',
      !!account?.data_policy_accepted,
      !!account?.process_accepted,
      willAttend,
      willAttend ? schedulingName : account?.attendant_name || '',
      willAttend ? schedulingPhone : account?.attendant_phone || '',
      !!account?.visitor_data_policy_accepted,
    );
  }
}
