import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { INIT_REPORT_FILTERS_DATA, SCHEDULES_REPORT } from '../data/gql';
import { IScheduleReport } from '../data/interfaces';
import { IReportFiltersInitData, TScheduleReportFilters } from '../data/types';

@Injectable({
  providedIn: 'root',
})
export class AdminReportsService {
  private readonly apolloClient = inject(Apollo);

  reportInitData() {
    return this.apolloClient
      .query<IReportFiltersInitData>({
        query: INIT_REPORT_FILTERS_DATA,
      })
      .pipe(
        take(1),
        map((res) => res.data),
      );
  }

  getScheduleReportData(filters: TScheduleReportFilters) {
    return this.apolloClient
      .mutate<{ consultSchedulesReport: IScheduleReport[] }>({
        mutation: SCHEDULES_REPORT,
        variables: { filters },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((res) => res.data.consultSchedulesReport),
      );
  }
}
