<section class="py-4">
  <div class="lg:p-4">
    <div class="flex gap-2 w-full justify-center px-4 xs:mb-2 lg:mb-4">
      <img
        class="w-8 h-8"
        src="assets/images/confirm-calendar.png"
        alt="icon calendar"
      />
      <h2 class="xs:text-xl lg:text-2xl font-bold">Confirmación cita</h2>
    </div>
    <div class="flex flex-col justify-center xs:mb-4 lg:mb-8 px-4">
      <p class="text-center">Estás agendando una cita programada para:</p>
      <strong class="w-full text-center xs:text-lg lg:text-xl">
        {{ dateformat }}, {{ appointment }}
      </strong>
    </div>
    <loader-content [isLoading]="isFetching()">
      <div class="flex flex-col">
        <div class="xs:px-4 lg:py-4">
          <otp-modal-content
            [phone]="phone"
            (onConfirm)="otpAction($event)"
            (onResetCode)="onResetOTPCode()"
            (countdownFinished)="onTimeOut()"
          />
        </div>
        <div
          class="flex justify-center xs:flex-col-reverse lg:flex-row w-full xs:gap-4 lg:gap-8 p-4"
        >
          <button class="btn btn-outline xs:w-full lg:w-40" (click)="onClose()">
            Regresar
          </button>
          <button
            class="btn btn-primary xs:w-full lg:w-40"
            [disabled]="isDisabledButton()"
            (click)="confirmOTP()"
          >
            Continuar
          </button>
        </div>
      </div>
    </loader-content>
  </div>
</section>
