<div class="single-card bg-blue">
  <form [formGroup]="formGroup" (submit)="submitForm()">
    <section class="flex flex-col gap-3 xs:p-2 md:p-4">
      <h1 class="font-extrabold text-lg">Ingresa los datos de cuenta</h1>
      <div
        class="xs:flex xs:flex-col md:grid md:grid-cols-12 md:items-end gap-4"
      >
        <div class="form-group md:col-span-3">
          <label class="xl:text-sm xxl:text-base font-normal flex gap-2">
            Cuenta contrato
            <span class="flex w-5 h-5" id="tooltip-default">
              <svg-icon style="background-color: #11171f" icon="alert-icon" />
            </span>
          </label>
          <input
            class="input-field"
            type="text"
            placeholder="Ingresa número de cuenta"
            mask="99999999"
            formControlName="account_contract"
          />
          <span class="sub-text">
            Cuenta o referencia de pago en la factura
          </span>
        </div>
        <div class="form-group md:col-span-3">
          <label class="xl:text-sm xxl:text-base font-normal">Nombre</label>
          <input
            class="input-field"
            type="text"
            placeholder="Ingresa el nombre"
            formControlName="scheduling_name"
          />
          @if (formGroup.get("scheduling_name").errors?.["pattern"]) {
            <span class="form-error">No es un nombre válido</span>
          }
        </div>
        <div class="form-group md:col-span-3">
          <label class="xl:text-sm xxl:text-base font-normal">Celular</label>
          <input
            class="input-field"
            type="text"
            placeholder="Ingresa número móvil"
            mask="(999) 999 9999"
            formControlName="scheduling_phone"
          />
        </div>
        @if ($canShowExtraField()) {
          <div class="form-group md:col-span-3">
            <label class="xl:text-sm xxl:text-base font-normal"
              >Técnico o quien refiere (opcional)</label
            >
            <input
              class="input-field"
              type="text"
              placeholder="Ingrese su cédula o número SAP"
              mask="9999999999"
              formControlName="technical_referral"
            />
          </div>
        }
      </div>
    </section>

    <section class="flex flex-col xs:p-2 md:p-4 gap-4 items-end">
      <div
        class="xs:p-1 md:p-4 w-full bg-[#F5F6F7] border rounded-lg flex flex-col gap-3 xs:text-sm md:text-base"
      >
        <div class="flex xs:gap-3 md:gap-4 xs:p-2 md:p-0">
          <span class="pt-1">
            <input
              type="checkbox"
              formControlName="data_policy_accepted"
            />
          </span>
          <p class="text-neutral-50">
            Autorizo a Grupo Vanti de manera libre, expresa, inequívoca e
            informada el tratamiento de sus datos personales de acuerdo con lo
            dispuesto en la Ley 1581/2012 y conforme a la política de
            tratamiento de datos personales publicada en
            <a
              style="word-break: break-all"
              class="underline font-semibold"
              target="_blank"
              href="https://www.grupovanti.com/conocenos/proteccion-de-datos-personales"
            >
              https://www.grupovanti.com/conocenos/proteccion-de-datos-personales</a
            >. Recuerde que para ejercer sus derechos frente al tratamiento de
            sus datos personales puede contactarnos a través de los canales
            indicados en la política de tratamiento de datos personales.
          </p>
        </div>
        <div class="flex xs:gap-3 md:gap-4 xs:p-2 md:p-0">
          <span class="pt-1">
            <input
              type="checkbox"
              formControlName="process_accepted"
            />
          </span>
          <p class="text-neutral-50">
            Doy fe de seleccionar a Grupo Vanti, para que a través de sus
            contratistas y/u Organismos de Inspección acreditados me brinden el
            servicio Revisión Periódica Obligatoria /Reparación de Instalación
            Interna). Habiendo conocido que en el mercado hay mas empresas que
            ofrecen este servicio.
          </p>
        </div>
      </div>
    </section>

    <section
      class="xs:flex xs:flex-col md:grid md:grid-cols-12 gap-4 xs:p-2 md:p-4"
    >
      <div class="form-group md:col-span-4">
        <label class="xl:text-sm xxl:text-base font-normal flex gap-2">
          ¿Quién atenderá la cita?
        </label>
        <sm-select
          [options]="[
            {
              value: 'YES',
              label: 'Yo',
            },
            {
              value: 'NO',
              label: 'Otra persona',
            },
          ]"
          formControlName="will_attend"
        />
      </div>
      @if ($showExtraData()) {
        <div class="form-group md:col-span-4">
          <label class="xl:text-sm xxl:text-base font-normal">
            Nombre y apellido de quien atiende la visita
          </label>
          <input
            class="input-field"
            type="text"
            placeholder="Ingrese el nombre"
            formControlName="attendant_name"
          />
        </div>
        <div class="form-group md:col-span-4">
          <label class="xl:text-sm xxl:text-base font-normal">
            Celular de quien atiende la visita
          </label>
          <input
            class="input-field"
            type="text"
            placeholder="Ingrese el número móvil"
            mask="(000) 000 0000"
            formControlName="attendant_phone"
          />
        </div>
      }
    </section>

    @if ($showExtraData()) {
      <section class="flex xs:gap-3 md:gap-4 xs:p-2 lg:p-4">
        <div
          class="xs:p-3 md:p-4 w-full bg-[#F5F6F7] border rounded-lg flex flex-col gap-3 xs:text-sm md:text-base"
        >
          <div class="flex gap-4">
            <span class="pt-1">
              <input
                type="checkbox"
                name="terms"
                formControlName="visitor_data_policy_accepted"
              />
            </span>
            <div class="flex flex-col gap-3">
              <p class="text-neutral-50">
                Declaro que quien agenda la visita, cuenta con autorización
                legitima del tercero quien atenderá la misma, para suministrar
                su nombre y número de contacto para uso exclusivo de la atención
                de este proceso.
              </p>
              <p class="text-neutral-50">
                Igualmente manifiesto expresamente mi consentimiento y
                autorización para que, en mi nombre y representación, la persona
                que atiende la visita pueda firmar los documentos
                correspondientes a la operación ejecutada y los servicios que se
                deriven de la misma.
              </p>
            </div>
          </div>
        </div>
      </section>
    }

    <section class="flex xs:justify-center lg:justify-end p-4">
      <button type="submit" class="btn btn-primary min-w-72" [disabled]="formGroup.invalid || $isLoading()">
        {{ $isLoading() ? 'Consultando...' : 'Continuar' }}
      </button>
    </section>
  </form>
</div>
