import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  model,
  Output,
  signal,
} from '@angular/core';
import {
  MatCalendarCellClassFunction,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';

import { DATE_DETAILS_FORMATS } from '@core/modules/capacities/data/constants';
import { DateTimeAdapter } from '@core/domain/adapters';

@Component({
  selector: 'sm-datepicker',
  templateUrl: './datepicker.component.html',
  standalone: true,
  providers: [
    provideLuxonDateAdapter(DATE_DETAILS_FORMATS),
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  imports: [MatDatepickerModule, MatCardModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent {
  today: DateTime = DateTimeAdapter.now();
  userSelectDate = model<DateTime | null>(null);

  readonly $selectedDates = signal<DateTime[]>([]);
  @Input() set selectedDates(dates: DateTime[]) {
    this.$selectedDates.set(dates);
  }
  @Output() dateSelected = new EventEmitter<DateTime>();

  onDateSelected(selectedDate: DateTime): void {
    this.dateSelected.emit(selectedDate);
  }

  dateClass: MatCalendarCellClassFunction<DateTime> = (date: DateTime) => {
    if (this.$selectedDates().some((d) => d.toISODate() === date.toISODate())) {
      return 'occupied-dates';
    }

    if (date.toISODate() === this.today.toISODate()) {
      return 'today-date';
    }

    return '';
  };
}
