import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class AppDocumentService {
  private readonly document = inject(DOCUMENT);

  replace(url: string) {
    this.document.location.replace(url);
  }

  newTab(url: string, target: string) {
    this.document.defaultView.open(url, target);
  }
}
