import { Component, inject, OnInit, signal, computed } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';

import { OtpModalContentComponent } from '../otp-modal-content/otp-modal-content.component';
import { LoaderContentComponent } from '../loader-content/loader-content.component';
import {
  TActionOPT,
  TParamsCreateAppointment,
  TStatusCloseModal,
} from '@core/shared/types';
import { SchedulerFacade } from '@core/domain/facades';
import { NotifyService } from '@core/shared/services';
import { MONTHS, StatusResponseCodes } from '@core/shared/constants';
import { TScheduleDateForm } from '@core/domain/entities/scheduler/types';

type TParamsData = {
  data: { modalData: TParamsCreateAppointment; formData: TScheduleDateForm };
};

@Component({
  selector: 'confirm-create-appointment',
  standalone: true,
  imports: [OtpModalContentComponent, LoaderContentComponent],
  templateUrl: './confirm-create-appointment.component.html',
})
export class ConfirmCreateAppointmentComponent implements OnInit {
  private readonly notify = inject(NotifyService);
  private readonly dialogRef = inject(
    MatDialogRef<ConfirmCreateAppointmentComponent>,
  );
  private readonly schedulerFacade = inject(SchedulerFacade);
  readonly paramsData = inject<TParamsData>(MAT_DIALOG_DATA);
  readonly otp = signal('');
  readonly isTimeoutDisabled = signal(false);
  readonly isFetching = signal(false);

  readonly isDisabledButton = computed(() => {
    return this.otp().length < 6 || this.isTimeoutDisabled();
  });

  ngOnInit() {
    this.makeRequestOTP();
  }

  get formData() {
    return this.paramsData.data.formData;
  }

  get appointment() {
    const data = this.paramsData.data.modalData.availableCapability;
    return data?.time_slots_info?.label;
  }

  get dateformat() {
    const date = this.paramsData.data.modalData?.availableCapability
      ?.date as unknown as DateTime;
    return `${date.day} de ${MONTHS.at(date.month)} de ${date.year}`;
  }

  get phone() {
    return this.paramsData.data.modalData?.phone;
  }

  onClose(obj: TStatusCloseModal = { status: 'CANCEL' }) {
    this.dialogRef.close(obj);
  }

  makeRequestOTP() {
    this.isFetching.set(true);
    this.schedulerFacade.generateOTPCodeByPhone(this.phone).subscribe({
      next: (resp) => {
        const errMessage = resp?.message || resp?.data?.message;

        if (resp.success === false) {
          this.notify.error('Espera!', errMessage);
          return;
        }

        if (resp.success && resp.data.status === 'ERROR') {
          this.onClose({
            status: 'ERROR',
            message: resp.data.message,
            code: resp.data.code,
          });
          return;
        }
      },
      complete: () => this.isFetching.set(false),
    });
  }

  otpAction(event: TActionOPT) {
    switch (event.action) {
      case 'CLOSE_MODAL':
        this.onClose();
        return;

      case 'CONFIRMED_OTP':
        this.otp.set(event.payload);
        return;
    }
  }

  onTimeOut() {
    this.isTimeoutDisabled.set(true);
  }

  onResetOTPCode() {
    this.isTimeoutDisabled.set(false);
    this.makeRequestOTP();
  }

  onRequestCreateSchedule() {
    this.isFetching.set(true);
    this.schedulerFacade.createScheduleByAccount(this.formData).subscribe({
      next: (resp) => {
        if (!resp.success) {
          this.notify.error('Espera', resp.message);
          this.onClose();
          return;
        }

        this.notify.done('Hecho', 'Se ha creado correctamente el agendamiendo');
        this.onClose({ status: 'SUCCESS', data: resp.data });
      },
      complete: () => this.isFetching.set(false),
    });
  }

  confirmOTP() {
    this.schedulerFacade.validateOTPCode(this.otp(), this.phone).subscribe({
      next: ({ data, success, message }) => {
        if (success === false) {
          this.notify.error('Espera!', message);
          return;
        }

        if (data?.status === 'ERROR') {
          if (data.code === StatusResponseCodes.OTP_REQUEST_ERROR) {
            this.onClose(data);
            return;
          } else {
            this.notify.warn('Espera', data.message);
            return;
          }
        }

        this.onRequestCreateSchedule();
      },
    });
  }
}
