import { gql } from 'apollo-angular';

export const SEARCH_ACCOUNT_DATA_CONTRACT = gql`
  query Query($contract: ValidateAccountContractDto!) {
    validateAccountContractData(contract: $contract) {
      status
      message
      code
      data {
        ownAccount
        address
        phone
        account
        city
        vantimax_coverage
        scheduleActive {
          id
          schedule_date
          type_service
          schedule_time
        }
        availableCapabilities {
          date
          total_capacity
          isActive
          time_slots_info {
            label
            slot
            capacity
          }
        }
      }
    }
  }
`;
