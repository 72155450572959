import { NgClass } from '@angular/common';
import { Component, computed, Input, signal } from '@angular/core';
import { RouterLink } from '@angular/router';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

type TBreadCrumb = {
  label: string;
  path: string;
};

@Component({
  selector: 'sm-breadcrumbs',
  standalone: true,
  imports: [SvgIconComponent, RouterLink, NgClass],
  templateUrl: './sm-breadcrumbs.component.html',
})
export class SmBreadcrumbsComponent {
  private readonly $pages = signal<string[]>([]);

  @Input('pages') set initPages(pages: string[]) {
    this.$pages.set(pages);
  }

  readonly pages = computed<TBreadCrumb[]>(() => {
    return this.$pages().map((page) => {
      const [label, path] = page.split('@');

      return { label, path };
    });
  });
}
