import { DateTimeAdapter } from '@core/domain/adapters';
import { TScheduleReportFilters } from '../types';

export function ReportSchedulesBuilder({
  end_date,
  start_date,
  account_contract,
  filial,
  operation,
  time_slot,
}: TScheduleReportFilters): TScheduleReportFilters {
  return {
    start_date: DateTimeAdapter.fromISO(start_date).toFormat('yyyy-MM-dd'),
    end_date: DateTimeAdapter.fromISO(end_date).toFormat('yyyy-MM-dd'),
    ...(!!account_contract
      ? { account_contract: account_contract.padStart(12, '0') }
      : {}),
    ...(!!filial ? { filial: filial.padStart(4, '0') } : {}),
    ...(!!operation ? { operation } : {}),
    ...(!!time_slot ? { time_slot } : {}),
  };
}
