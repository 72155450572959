import { gql } from 'apollo-angular';

export const LIST_CREATE_SCHEDULE_DATA = gql`
  query Query {
    listAccountServiceType {
      id
      service_type
      status
    }
  }
`;

export const REQUEST_OTP_CODE = gql`
  mutation GenerateOTP($body: OtpPhoneDto!) {
    generateOTP(body: $body) {
      message
      status
      code
    }
  }
`;

export const CONFIRM_OTP_CODE = gql`
  query Query($body: OtpCodeDto!) {
    validateOTP(body: $body) {
      message
      status
      code
    }
  }
`;

export const CREATE_NEW_SCHEDULE_BY_ACCOUNT = gql`
  mutation Mutation($contract: CreateScheduleDto!) {
    createSchedule(contract: $contract) {
      owner_account
      address_account
      attendant_phone
      account_contract
      city
      coverage_vantimax
      schedule_date
      time_slot
      service_type {
        service_type
        id
      }
    }
  }
`;

export const SOFT_DELETE_SCHEDULE = gql`
  mutation Mutation($scheduleId: String!) {
    softDeleteSchedule(scheduleId: $scheduleId)
  }
`;
