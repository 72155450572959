import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'opt-input',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './opt-input.component.html',
})
export class OptInputComponent implements OnInit, OnDestroy {
  $subs?: Subscription;
  readonly otpFormArray = new FormArray<FormControl>([]);

  readonly $length = signal(4);
  @Input() set length(len: number) {
    this.$length.set(len);
  }

  @Output() onValueChange = new EventEmitter<string>();
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;

  ngOnInit() {
    this.createFormArray();

    this.$subs = this.otpFormArray?.valueChanges?.subscribe((value) => {
      const otpCode = value.join('');

      this.onValueChange.emit(otpCode);
    });
  }

  onInput(event: Event, index: number) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.value.length === 1 && index < this.$length() - 1) {
      this.focusNextInput(index + 1);
    }
  }

  createFormArray() {
    this.otpFormArray.clear();

    for (let i = 0; i < this.$length(); i++) {
      const control = new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]$'),
      ]);

      this.otpFormArray.push(control);
    }
  }

  focusNextInput(index: number) {
    const nextInput = this.otpInputs.get(index);
    if (nextInput) {
      nextInput.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.$subs?.unsubscribe();
  }
}
