<div class="flex flex-col gap-3 p-4 items-center lg:w-[440px]">
  <picture class="flex w-52">
    <img
      class="w-full"
      src="assets/images/vanti-thinking.png"
      alt="something was wrong"
    />
  </picture>
  <h3 class="font-bold text-2xl text-center">{{ title }}</h3>
  <p class="text-center">
    {{ description }}
  </p>

  <ng-content/>

</div>
