import { gql } from 'apollo-angular';

export const GET_INIT_FILTERS_DATA = gql`
  query Query($filters: CapabilityFiltersDto!) {
    listOperations {
      id
      name
      label
    }
    zoneList {
      id
      code
      name
    }
    capabilitiesByMonth(filters: $filters) {
      active
      available
      date
      scheduled
      total
    }
  }
`;

export const GET_FILTERS_DATA = gql`
  query Query($filters: CapabilityFiltersDto!) {
    capabilitiesByMonth(filters: $filters) {
      active
      available
      date
      scheduled
      total
    }
  }
`;

export const GET_INIT_FILTERS_DETAILS_DATA = gql`
  query CapabilitiesSegmented(
    $filters: CapabilityFiltersDto!
    $filtersBags: CapabilityFiltersDto!
    $pagination: PaginationDto!
    $segmentedZonesFilters: SegmentedZonesFilters
  ) {
    capabilitiesByDay(filters: $filters) {
      zone_id
      zone_name
      segmented_zone_id
      segmented_zone_name
      initial_capability
      total_scheduled
      total_available
      percentage_am
      percentage_pm
      available_am
      available_pm
      operation_label
      active
      capability_type
    }
    capabilitiesSegmentedBags(filters: $filtersBags) {
      zone_id
      zone_name
      segmented_zone_id
      segmented_zone_name
      initial_capacity
      total_scheduled
      total_available
      percentage_am
      percentage_pm
      available_am
      available_pm
      operation_label
      active
      capability_type
      percentage
      bag {
        id
        name
      }
    }
    segmentedZones(pagination: $pagination, filters: $segmentedZonesFilters) {
      data {
        code
        id
        name
      }
    }
  }
`;

export const GET_CAPABILITIES_BY_DATE = gql`
  query Query(
    $filters: CapabilityFiltersDto!
    $filtersBags: CapabilityFiltersDto!
  ) {
    capabilitiesByDay(filters: $filters) {
      zone_id
      zone_name
      segmented_zone_id
      segmented_zone_name
      initial_capability
      total_scheduled
      total_available
      percentage_am
      percentage_pm
      available_am
      available_pm
      operation_label
      active
      capability_type
    }
    capabilitiesSegmentedBags(filters: $filtersBags) {
      zone_id
      zone_name
      segmented_zone_id
      segmented_zone_name
      initial_capacity
      total_scheduled
      total_available
      percentage_am
      percentage_pm
      available_am
      available_pm
      operation_label
      active
      capability_type
      percentage
      bag {
        id
        name
      }
    }
  }
`;

export const GET_CAPABILITIES_BAGS_BY_DATE = gql`
  query Query($filters: CapabilityFiltersDto!) {
    capabilitiesSegmentedBags(filters: $filters) {
      zone_id
      zone_name
      segmented_zone_id
      segmented_zone_name
      initial_capacity
      total_scheduled
      total_available
      percentage_am
      percentage_pm
      available_am
      available_pm
      operation_label
      active
      capability_type
      percentage
      bag {
        id
        name
      }
    }
  }
`;

export const UPDATE_CAPABILITIES_BY_DAY = gql`
  mutation UpdateCapability($data: UpdateCapabilityByDayDto!) {
    updateCapability(data: $data)
  }
`;

export const UPDATE_SEGMENTED_ZONES_BY_DAY = gql`
  mutation UpdateCapabilitySegmentedZones($data: UpdateSegmentedZoneByDayDto!) {
    updateCapabilitySegmentedZones(data: $data)
  }
`;

export const UPDATE_SEGMENTED_ZONES_BAGS = gql`
  mutation UpdateSegmentedZonesBagsByDay($data: UpdateSegmentedZoneBagsDto!) {
    updateSegmentedZonesBagsByDay(data: $data)
  }
`;
