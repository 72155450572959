import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { UserStore } from '@core/stores';

export const selfsGuard: CanActivateFn = (route) => {
  const store = inject(UserStore);
  const params = (route.queryParams as Record<string, string>)?.['x'];
  const xUserTypes = ['Technician'];

  if (xUserTypes.includes(params)) {
    store.doSetXUser(params);
  }

  return true;
};
