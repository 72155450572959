import { ValidatorFn, FormGroup } from '@angular/forms';

import { TSearchAccountContract } from '@core/domain/entities/scheduler/types';

export function searchAccountContractValidator(): ValidatorFn {
  return function validate(contract: FormGroup) {
    const {
      will_attend,
      attendant_name,
      attendant_phone,
      visitor_data_policy_accepted,
    } = contract.value as TSearchAccountContract;

    const validations = [
      !!attendant_name,
      !!attendant_phone,
      visitor_data_policy_accepted,
    ];

    if (will_attend === 'NO' && !validations.every(Boolean)) {
      return { attend_required: 'Required' };
    }

    return null;
  };
}
