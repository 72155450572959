import packageJson from '../../package.json';

export const environment = {
  production: false,
  servicesUrl: 'https://sm-api-dev.vanti.tech/gql/graphql',
  callbackUrl: 'https://sm-backoffice-dev.vanti.tech/login/verify',
  ipPublicUrl: 'https://api.ipify.org?format=json',
  limitTokenInHours: 1,
  recaptchaKey: '6Lc1IBAqAAAAAJ_TM1cMa6hN0sUEUCWJtr9EFR_m',
  version: `${packageJson.version}-beta`,
  inactiveTimer: 600000, // 10 min
  capabilitiesFilters: {
    zoneId: '18',
    operationId: 'rep_mtto',
  },
  roleSelector: [
    { value: 'agente canales', label: 'Agente Canales' },
    { value: 'consulta', label: 'Consulta' },
    { value: 'administrador', label: 'Administrador' },
  ],
  apiKeyPublicAccess: '1FrdJusbpGkFM8jajt7dvrZ0bQDFw3DCkySFJDrk',
  publicTypeUser: {
    TECHNICIAN: 'Technician',
    CUSTOMER: 'Customer',
  },
};
