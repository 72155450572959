import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { DateTime } from 'luxon';

import { SchedulerService } from '@core/shared/services/scheduler';
import {
  TAvailableCapabilityFormData,
  TScheduleDateForm,
  TScheduleServiceOption,
  TSlot,
} from '@core/domain/entities/scheduler/types';
import {
  AccountContractBuilder,
  AccountInfo,
  AvailableCapabilityEntity,
  ScheduleActiveEntity,
} from '@core/domain/entities/scheduler/entities';
import { MONTHS, ScheduleTime } from '@core/shared/constants';
import { IScheduleFormData } from '@core/shared/types';

type ScheduleState = {
  accountContract: AccountContractBuilder | null;
  scheduleActives: ScheduleActiveEntity[];
  accountInfo: AccountInfo | null;
  availableCapabilities: AvailableCapabilityEntity[];
  listSchedulerServiceTypes: TScheduleServiceOption[];
  scheduleDateForm: TScheduleDateForm | null;
  isFetching: boolean;
};

const initialState: ScheduleState = {
  accountContract: null,
  scheduleActives: [],
  accountInfo: null,
  availableCapabilities: [],
  listSchedulerServiceTypes: [],
  scheduleDateForm: null,
  isFetching: false,
};

export const ScheduleStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => ({
    currentSchedule: computed<ScheduleActiveEntity | null>(
      () => store.scheduleActives()?.at(0) || null,
    ),
    currentScheduleDate: computed<string>(() => {
      const dateItem = store.scheduleActives()?.at(0)?.scheduleDate;
      return `${dateItem?.day} de ${MONTHS[dateItem?.month]} de ${dateItem?.year}`;
    }),
    currentPhone: computed<string>(
      () => store.accountContract()?.scheduling_phone,
    ),
    currentScheduleTime: computed<string>(() => {
      const currentSchedule = store.scheduleActives()?.at(0);
      if (!currentSchedule?.scheduleTime) {
        return '';
      }

      return ScheduleTime[currentSchedule.scheduleTime];
    }),
    buildFormDataSchedule: computed<IScheduleFormData>(() => ({
      ...store.accountContract(),
      service_type: store.scheduleDateForm()?.serviceSchedule,
      time_slot: store.scheduleDateForm()?.journeyControl as TSlot,
      service_date: store.scheduleDateForm()?.date?.toISO(),
    })),
  })),
  withMethods((store) => {
    const schedulerService = inject(SchedulerService);

    return {
      doClear: () => {
        patchState(store, initialState);
      },
      doToggleFetching: (isFetching: boolean) => {
        patchState(store, { isFetching });
      },
      doSetAccountContract: (account: AccountContractBuilder | null) => {
        patchState(store, { accountContract: account });
      },
      doSetScheduleActives: (schedules: ScheduleActiveEntity[]) => {
        patchState(store, { scheduleActives: schedules });
      },
      doSetAccountInfo: (account: AccountInfo | null) => {
        patchState(store, { accountInfo: account });
      },
      doSetAvailableCapabilities: (
        availableCapabilities: AvailableCapabilityEntity[],
      ) => {
        patchState(store, { availableCapabilities });
      },
      doSetScheduleDateForm: (scheduleDateForm: TScheduleDateForm | null) => {
        patchState(store, { scheduleDateForm });
      },
      doGetListSchedulerServices: rxMethod<undefined>(
        pipe(
          tap(() => patchState(store, { isFetching: true })),
          switchMap(() => {
            return schedulerService.getCreateScheduleOptions().pipe(
              tapResponse({
                next: (data) =>
                  patchState(store, {
                    listSchedulerServiceTypes: data,
                    isFetching: false,
                  }),
                error: () => patchState(store, { isFetching: false }),
              }),
            );
          }),
        ),
      ),
      showAvailableCapability: (
        date: DateTime,
        slot: TSlot,
      ): TAvailableCapabilityFormData => {
        const item = store
          .availableCapabilities()
          .find(
            (ac) =>
              ac.date.toFormat('yyyy-MM-dd') === date.toFormat('yyyy-MM-dd'),
          );

        return {
          date: item.date,
          total_capacity: item.totalCapacity,
          isActive: item.isActive,
          time_slots_info: item.timeSlotsInfo.find((ts) => ts.slot === slot),
        };
      },
    };
  }),
);
