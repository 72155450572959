import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { map } from 'rxjs';

import { createMenuModules } from '@core/domain/builders';
import { UIStore, UserStore } from '@core/stores';

@Injectable({
  providedIn: 'root',
})
export class UIFacade {
  private readonly router = inject(Router);
  private readonly userStore = inject(UserStore);
  private readonly uiStore = inject(UIStore);

  readonly $isLazyLoading = toSignal(
    this.router.events.pipe(
      map((event) => {
        if (event instanceof RouteConfigLoadStart) {
          return true;
        } else if (event instanceof RouteConfigLoadEnd) {
          return false;
        }
        return false;
      }),
    ),
  );

  get loadingStack() {
    return this.uiStore.loadingStack();
  }

  get isSidebarOpen() {
    return this.uiStore.isSidebarOpen();
  }

  get sidebarModules() {
    return createMenuModules(this.userStore.authUser().permissionModules);
  }

  public setLoadingStack(item: string) {
    this.uiStore.onSetLoadingStack(item);
  }

  public setIsSidebarOpen(isOpen: boolean) {
    this.uiStore.onSetIsSidebarOpen(isOpen);
  }
}
