import { Pipe, PipeTransform } from '@angular/core';

import { obscureText } from '@core/domain/helpers';

@Pipe({
  name: 'maskedText',
  standalone: true,
})
export class MaskedTextPipe implements PipeTransform {
  transform(value: string, init: number, end: number): string {
    return obscureText(value, init, end);
  }
}
