import { Routes } from '@angular/router';

import { authGuard, authorizedGuard } from './shared/guards';
import { authRoutes } from './modules/auth/auth.routes';

export const routes: Routes = [
  { path: 'login', children: authRoutes },
  {
    path: 'scheduler',
    loadChildren: () =>
      import('./modules/scheduler/scheduler.module').then(
        (m) => m.SchedulerModule,
      ),
    canActivate: [authGuard, authorizedGuard],
  },
  {
    path: 'capacity',
    loadChildren: () =>
      import('./modules/capacities/capacities.module').then(
        (m) => m.CapacitiesModule,
      ),
    canActivate: [authGuard, authorizedGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuard, authorizedGuard],
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  { path: '**', redirectTo: 'scheduler' },
];
