import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { CapabilitiesService } from '../modules/capacities/services';
import {
  ICapabilitiesByDate,
  ICapabilitiesByDateData,
  ICapabilitiesFilters,
  ICapabilitiesViewByDay,
  TUpdateBags,
  TUpdateCapabilitiesByDay,
  TUpdateSegmentedZonesByDay,
} from '@core/modules/capacities/data/types';
import {
  CapabilityDay,
  CapabilityWithBags,
  mapCapabilityBags,
  mapCapabilityDayList,
} from '@core/modules/capacities/data/entities';
import { IResponseMap } from '@core/domain/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesBlocService {
  private readonly capabilitiesService = inject(CapabilitiesService);

  initCapabilitiesData(filters: ICapabilitiesFilters) {
    return this.capabilitiesService.getFiltersData(filters).pipe(
      map((data) => ({
        success: true,
        data: {
          operations: data.listOperations,
          zones: data.zoneList,
          data: data.capabilitiesByMonth,
        },
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: { operations: [], zones: [], data: [] },
        }),
      ),
    );
  }

  initCapabilitiesViewDataByZoneId(
    zoneId: string,
    filters: ICapabilitiesFilters,
  ): Observable<IResponseMap<ICapabilitiesViewByDay>> {
    return this.capabilitiesService
      .getCapabilitiesViewDataByZoneId(zoneId, filters)
      .pipe(
        map((data) => ({
          success: true,
          data: {
            segmentedZones: data.segmentedZones.data,
            capabilitiesByDay: mapCapabilityDayList(
              data.capabilitiesByDay.filter(
                (c) => c.capability_type !== 'FILIAL',
              ),
            ),
            filial: CapabilityDay.fromJson(
              data.capabilitiesByDay.find(
                (c) => c.capability_type === 'FILIAL',
              ) ?? {},
            ),
            capabilitiesSegmentedBags: mapCapabilityBags(
              data.capabilitiesSegmentedBags,
            ),
          },
        })),
        catchError((err) =>
          of({
            success: false,
            message: err.message,
            data: {
              segmentedZones: [],
              capabilitiesByDay: [],
              filial: null,
              capabilitiesSegmentedBags: [],
            },
          }),
        ),
      );
  }

  filterChartData(filters: ICapabilitiesFilters) {
    return this.capabilitiesService.getFiltersChartData(filters).pipe(
      map((data) => ({
        success: true,
        data: data.capabilitiesByMonth,
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: [],
        }),
      ),
    );
  }

  private getCapabilitiesByDay(data: ICapabilitiesByDateData) {
    return {
      capabilitiesByDay: mapCapabilityDayList(
        data.capabilitiesByDay.filter((c) => c.capability_type !== 'FILIAL'),
      ),
      filial: CapabilityDay.fromJson(
        data.capabilitiesByDay.find((c) => c.capability_type === 'FILIAL') ??
          {},
      ),
      capabilitiesSegmentedBags: mapCapabilityBags(
        data.capabilitiesSegmentedBags,
      ),
    };
  }

  updateCapabilitiesByDay(
    formData: TUpdateCapabilitiesByDay,
    filters: ICapabilitiesFilters,
  ): Observable<IResponseMap<ICapabilitiesByDate>> {
    return this.capabilitiesService.updateCapabilitiesByDay(formData).pipe(
      switchMap(() =>
        this.capabilitiesService
          .getCapabilitiesByDay(filters)
          .pipe(map((data) => this.getCapabilitiesByDay(data))),
      ),
      map((data) => ({
        success: true,
        data,
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: {
            capabilitiesByDay: [],
            filial: null,
            capabilitiesSegmentedBags: [],
          },
        }),
      ),
    );
  }

  updateSegmentedZonesByDay(
    data: TUpdateSegmentedZonesByDay,
    filters: ICapabilitiesFilters,
  ): Observable<IResponseMap<ICapabilitiesByDate>> {
    return this.capabilitiesService.updateSegmentedZonesByDay(data).pipe(
      switchMap(() =>
        this.capabilitiesService
          .getCapabilitiesByDay(filters)
          .pipe(map(this.getCapabilitiesByDay)),
      ),
      map((data) => ({
        success: true,
        data,
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: {
            capabilitiesByDay: [],
            filial: null,
            capabilitiesSegmentedBags: [],
          },
        }),
      ),
    );
  }

  updateSegmentedZonesBags(
    data: TUpdateBags,
    filters: ICapabilitiesFilters,
  ): Observable<IResponseMap<CapabilityWithBags[]>> {
    return this.capabilitiesService.updateSegmentedZonesBags(data).pipe(
      switchMap(() =>
        this.capabilitiesService
          .getCapabilitiesBagsByDate(filters)
          .pipe(
            map((data) => mapCapabilityBags(data.capabilitiesSegmentedBags)),
          ),
      ),
      map((data) => ({
        success: true,
        data,
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: [],
        }),
      ),
    );
  }
}
