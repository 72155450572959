import { Component, Input, signal } from '@angular/core';

@Component({
  selector: 'loader-content',
  standalone: true,
  imports: [],
  templateUrl: './loader-content.component.html',
})
export class LoaderContentComponent {
  readonly $isLoading = signal(false);

  @Input() set isLoading(isLoading: boolean) {
    this.$isLoading.set(isLoading);
  }
}
