import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NotifyService } from '@core/shared/services';
import { LoaderContentComponent } from '../loader-content/loader-content.component';
import { OtpModalContentComponent } from '../otp-modal-content/otp-modal-content.component';
import {
  TActionOPT,
  TParamsCreateAppointment,
  TStatusCloseModal,
} from '@core/shared/types';
import { SchedulerFacade } from '@core/domain/facades';
import {
  MONTHS,
  ScheduleTime,
  StatusResponseCodes,
} from '@core/shared/constants';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ScheduleActiveEntity } from '@core/domain/entities/scheduler/entities';
import { TitleCasePipe } from '@angular/common';

type TParamsData = {
  data: {
    modalData: TParamsCreateAppointment;
    schedule: ScheduleActiveEntity;
  };
};
type TCancelAppointmentViewState = 'OTP' | 'CONFIRMATION' | 'DONE';

@Component({
  selector: 'confirm-cancel-appointment',
  standalone: true,
  imports: [
    TitleCasePipe,
    OtpModalContentComponent,
    LoaderContentComponent,
    SvgIconComponent,
  ],
  templateUrl: './confirm-cancel-appointment.component.html',
})
export class ConfirmCancelAppointmentComponent implements OnInit {
  private readonly notify = inject(NotifyService);
  private readonly dialogRef = inject(
    MatDialogRef<ConfirmCancelAppointmentComponent>,
  );
  private readonly schedulerFacade = inject(SchedulerFacade);

  readonly paramsData = inject<TParamsData>(MAT_DIALOG_DATA);
  readonly otp = signal('');
  readonly isTimeoutDisabled = signal(false);
  readonly isFetching = signal(false);
  readonly cancelViewState = signal<TCancelAppointmentViewState>('OTP');

  readonly isDisabledButton = computed(() => {
    return this.otp().length < 6 || this.isTimeoutDisabled();
  });

  ngOnInit() {
    this.makeRequestOTP();
  }

  get phone() {
    return this.paramsData.data?.modalData?.phone;
  }

  get scheduleId() {
    return this.paramsData.data.schedule.id;
  }

  get schedule() {
    return this.paramsData.data.schedule;
  }

  get labelSchedule() {
    const { day, month, year } = this.schedule.scheduleDate;
    const journey = ScheduleTime[this.schedule.scheduleTime];

    return `${this.schedule.typeService}. ${day} ${MONTHS[month]} ${year}, ${journey}`;
  }

  onClose(obj: TStatusCloseModal = { status: 'CANCEL' }) {
    this.dialogRef.close(obj);
  }

  otpAction(event: TActionOPT) {
    switch (event.action) {
      case 'CLOSE_MODAL':
        this.onClose();
        return;

      case 'CONFIRMED_OTP':
        this.otp.set(event.payload);
        return;
    }
  }

  onTimeOut() {
    this.isTimeoutDisabled.set(true);
  }

  onResetOTPCode() {
    this.isTimeoutDisabled.set(false);
    this.makeRequestOTP();
  }

  makeRequestOTP() {
    this.isFetching.set(true);
    this.schedulerFacade.generateOTPCodeByPhone(this.phone).subscribe({
      next: (resp) => {
        const errMessage = resp?.message || resp?.data?.message;

        if (resp.success === false) {
          this.notify.error('Espera!', errMessage);
          return;
        }

        if (resp.success && resp.data.status === 'ERROR') {
          this.onClose({
            status: 'ERROR',
            message: resp.data.message,
            code: resp.data.code,
          });
          return;
        }
      },
      complete: () => this.isFetching.set(false),
    });
  }

  confirmOTP() {
    this.isFetching.set(true);
    this.schedulerFacade.validateOTPCode(this.otp(), this.phone).subscribe({
      next: ({ data, success, message }) => {
        if (success === false) {
          this.notify.error('Espera!', message);
          return;
        }

        if (data?.status === 'ERROR') {
          if (data.code === StatusResponseCodes.OTP_REQUEST_ERROR) {
            this.onClose(data);
            return;
          } else {
            this.notify.warn('Espera', data.message);
            return;
          }
        }

        this.cancelViewState.set('CONFIRMATION');
      },
      complete: () => this.isFetching.set(false),
    });
  }

  confirmCancelSchedule() {
    this.isFetching.set(true);
    this.schedulerFacade.cancelSchedule(this.scheduleId).subscribe({
      next: (resp) => {
        if (resp.success === false) {
          this.notify.error('Espera!', resp.message);
          return;
        }

        this.cancelViewState.set('DONE');
      },
      complete: () => this.isFetching.set(false),
    });
  }

  goToVanti() {
    this.onClose({ status: 'SUCCESS', data: 'VANTI' });
  }

  newSchedule() {
    this.onClose({ status: 'SUCCESS', data: 'NEW_SCHEDULE' });
  }
}
