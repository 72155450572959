import { Component, Input } from '@angular/core';
import { NgxMaskPipe } from 'ngx-mask';

import { AccountInfo } from '@core/domain/entities/scheduler/entities';

@Component({
  selector: 'account-info',
  standalone: true,
  imports: [NgxMaskPipe],
  templateUrl: './account-info.component.html',
})
export class AccountInfoComponent {
  @Input() account: AccountInfo;
}
