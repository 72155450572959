import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { computed } from '@angular/core';

import { AuthUser } from '@core/domain/entities';
import { getPayload } from '@core/domain/helpers';
import { environment } from '@env/environment';
import { IPermissionsUser } from '@core/domain/interfaces';

type TUserState = {
  token: string;
  xUser: string;
};

const initialState: TUserState = {
  token: '',
  xUser: '',
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ token }) => ({
    authUser: computed(() => {
      return AuthUser.fromToken(token());
    }),
    isAuthActive: computed(() => {
      const payload = getPayload(token());

      if (!payload) {
        return false;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      const limitInSeconds = environment.limitTokenInHours * 60 * 60;
      const isExpiredToken = currentTime - payload!.iat > limitInSeconds;
      const isUserActive = [!!token(), !isExpiredToken].every(Boolean);

      return isUserActive;
    }),
    permissions: computed<IPermissionsUser>(() => {
      const authUser = AuthUser.fromToken(token());

      return {
        role: authUser.userRole,
        module: [authUser.permissionModules],
      } as IPermissionsUser;
    }),
  })),
  withMethods((store) => ({
    onClear: () => {
      patchState(store, initialState);
    },
    onSetToken: (token: string) => {
      patchState(store, { token });
    },
    doSetXUser: (xUser: string) => {
      patchState(store, { xUser });
    },
  })),
);
