import { toTitleCase } from '@core/domain/helpers';
import { ICapabilitiesDays, TCapabilityType } from '../types';

export const mapCapabilityDayList = (
  capabilities: Partial<ICapabilitiesDays>[],
) => capabilities.map((cap) => CapabilityDay.fromJson(cap));

export class CapabilityDay {
  private constructor(
    readonly zoneId: string,
    readonly zoneName: string,
    readonly segmentedZoneId: string,
    readonly segmentedZoneName: string,
    readonly initialCapability: number,
    readonly available: number,
    readonly scheduled: number,
    readonly percentageAm: number,
    readonly percentagePm: number,
    readonly availableAm: number,
    readonly availablePm: number,
    readonly operationLabel: string,
    readonly capabilityType: TCapabilityType,
    readonly active: boolean,
  ) {}

  static fromJson(capability: Partial<ICapabilitiesDays>) {
    return new CapabilityDay(
      capability?.zone_id ?? '',
      toTitleCase(capability?.zone_name ?? ''),
      capability?.segmented_zone_id || 'no_segmented',
      toTitleCase(capability?.segmented_zone_name || 'Sin asignación'),
      capability?.initial_capability ?? 0,
      capability?.total_available ?? 0,
      capability?.total_scheduled ?? 0,
      capability?.percentage_am ?? 0,
      capability?.percentage_pm ?? 0,
      capability?.available_am ?? 0,
      capability?.available_pm ?? 0,
      toTitleCase(capability?.operation_label ?? ''),
      capability?.capability_type ?? 'NO_SEGMENTED',
      capability?.active ?? false,
    );
  }

  get isSegmentedZone() {
    return this.capabilityType === 'SEGMENTED_ZONE';
  }

  copyWith(capability: Partial<ICapabilitiesDays>) {
    return new CapabilityDay(
      capability?.zone_id ?? this.zoneId,
      toTitleCase(capability?.zone_name ?? this.zoneName),
      capability?.segmented_zone_id ?? this.segmentedZoneId,
      toTitleCase(capability?.segmented_zone_name ?? this.segmentedZoneName),
      capability?.initial_capability ?? this.initialCapability,
      capability?.total_available ?? this.available,
      capability?.total_scheduled ?? this.scheduled,
      capability?.percentage_am ?? this.percentageAm,
      capability?.percentage_pm ?? this.percentagePm,
      capability?.available_am ?? this.availableAm,
      capability?.available_pm ?? this.availablePm,
      toTitleCase(capability?.operation_label ?? this.operationLabel),
      capability?.capability_type ?? this.capabilityType,
      capability?.active ?? this.active,
    );
  }
}
