import { TOption } from '@core/domain/types';

export enum ScheduleTime {
  PM = 'Jornada PM: 01:00 PM - 18:00 PM',
  AM = 'Jornada AM: 07:00 AM - 12:00 PM',
}

export const LIST_SCHEDULE_TIME: TOption[] = [
  { value: 'AM', label: ScheduleTime.AM },
  { value: 'PM', label: ScheduleTime.PM },
];
