import { Component, Input } from '@angular/core';

import { IUser } from '@core/domain/interfaces';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent {
  @Input() title: string = '';
  @Input() isOpen: boolean = false;
  @Input() user: IUser = { name: '' } as IUser;

  userShortName() {
    const [firstName, secondName = '', lastName = ''] =
      this.user?.name?.split(' ');
    const secondLetter = !!lastName ? lastName : secondName;

    return `${firstName.at(0)?.toLocaleUpperCase()}${secondLetter.at(0)?.toLocaleUpperCase()}`;
  }
}
