import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { TExcelHeader } from '@core/domain/types';

@Injectable({
  providedIn: 'platform',
})
export class ExcelFilesService {
  async generateExcel(
    columns: TExcelHeader[],
    data: unknown[],
    fileName: string,
  ): Promise<void> {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = columns;
    worksheet.addRows(data);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, `${fileName}.xlsx`);
  }
}
