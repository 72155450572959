import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogThinkingComponent } from '../dialog-thinking/dialog-thinking.component';

type TParamsData = { data: string };

@Component({
  selector: 'error-schedule',
  standalone: true,
  imports: [DialogThinkingComponent],
  templateUrl: './error-schedule.component.html',
})
export class ErrorScheduleComponent {
  private readonly _dialogRef = inject(MatDialogRef<ErrorScheduleComponent>);
  private readonly paramsData = inject<TParamsData>(MAT_DIALOG_DATA);

  get textMessage() {
    return this.paramsData.data;
  }

  onClose() {
    this._dialogRef.close();
  }
}
