import { inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpInterceptorFn } from '@angular/common/http';

import { environment } from '@env/environment';
import { UserStore } from '@core/stores';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(UserStore);
  const document = inject(DOCUMENT);
  const pathName = document?.location?.pathname;
  const auxPath = pathName?.split('/') || [];

  const headers: Record<string, string> = {
    'x-application': 'smart-mobility',
    'x-role': 'tecnico',
  };

  if (auxPath?.at(1) === 'public') {
    headers['x-key'] = environment.apiKeyPublicAccess;
    headers['x-user'] = !!store.xUser()
      ? environment.publicTypeUser.TECHNICIAN
      : environment.publicTypeUser.CUSTOMER;
  }

  const clonedRequest = req.clone({
    setHeaders: headers,
  });

  return next(clonedRequest);
};
