import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type TUIState = {
  loadingStack: string[];
  isSidebarOpen: boolean;
};

const initialState: TUIState = {
  loadingStack: [],
  isSidebarOpen: false,
};

export const UIStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => ({
    onSetLoadingStack: (item: string) => {
      patchState(store, ({ loadingStack }) => ({
        loadingStack: [...loadingStack, item],
      }));
    },
    onSetIsSidebarOpen(isOpen: boolean) {
      patchState(store, { isSidebarOpen: isOpen });
    },
  })),
);
