<section class="py-4">
  <div class="lg:p-4">
    <div class="flex justify-center min-h-[400px] min-w-[400px]">
      <loader-content [isLoading]="isFetching()">
          @switch (cancelViewState()) {
            @case ("OTP") {
              <div class="flex flex-col">
                <div class="xs:px-4 lg:py-4">
                  <otp-modal-content
                    [phone]="phone"
                    (onConfirm)="otpAction($event)"
                    (onResetCode)="onResetOTPCode()"
                    (countdownFinished)="onTimeOut()"
                  />
                </div>
                <div
                  class="flex justify-center xs:flex-col-reverse lg:flex-row w-full xs:gap-4 lg:gap-8 p-4"
                >
                  <button class="btn btn-outline xs:w-full lg:w-40" (click)="onClose()">
                    Regresar
                  </button>
                  <button
                    class="btn btn-primary xs:w-full lg:w-40"
                    [disabled]="isDisabledButton()"
                    (click)="confirmOTP()"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            }
  
            @case ("CONFIRMATION") {
              <div class="xs:px-4 lg:py-4 max-w-[500px]">
                <div class="flex flex-col gap-3 items-center">
                  <span class="w-8 h-8">
                    <svg-icon style="background-color: #DB4B4B;" icon="exclamation-triangle" />
                  </span>
                  <h2 class="text-2xl font-bold">
                    Vamos a cancelar tu cita
                  </h2>
                  <p class="text-center py-2 px-4">
                    Estas por descartar una cita ya agendada, <strong>{{ labelSchedule | titlecase }}</strong>
                  </p>
                  <strong class="blue-card font-bold text-lg p-2 text-center">
                    Recuerda que luego de cancelar tu cita podrás volver a agendar una nueva
                  </strong>
                  <p>
                    ¿Estas seguro?
                  </p>
                  <div class="flex xs:flex-col lg:flex-row gap-3 w-full justify-around p-4">
                    <button class="btn btn-outline xs:w-full lg:w-40" (click)="onClose()">Regresar</button>
                    <button class="btn btn-primary xs:w-full lg:w-40" (click)="confirmCancelSchedule()">Confirmar</button>
                  </div>
                </div>
              </div>
            }
  
            @case ("DONE") {
              <div class="flex flex-col gap-3 p-4 items-center lg:w-[500px]">
                <picture class="flex w-36">
                  <img
                    class="w-full"
                    src="assets/images/vanti-ok.png"
                    alt="success request"
                  />
                </picture>
                <h3 class="font-bold text-2xl text-center">Tu cita se ha eliminado con éxito</h3>
                <p class="text-center">
                  Si deseas agendar una cita nueva, haz clic en el botón 'Agendar cita' o selecciona la opción 'Ir a apartado Vanti' y navega por nuestra web Vanti para conocer más sobre nosotros.
                </p>
              
                <div class="flex xs:flex-col lg:flex-row gap-3 w-full justify-around p-4">
                  <button class="btn btn-outline xs:w-full lg:w-52" (click)="goToVanti()">Ir a apartado vanti</button>
                  <button class="btn btn-primary xs:w-full lg:w-52" (click)="newSchedule()">Agendar cita</button>
                </div>
              
              </div>
            }
          }
      </loader-content>
    </div>
  </div>
</section>
