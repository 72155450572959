import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';

import { AdminReportsService } from '@core/modules/admin/services';
import { onCatchError } from '../helpers';
import { IResponseMap } from '../interfaces';
import {
  IReportFiltersInitData,
  TScheduleReportFilters,
} from '@core/modules/admin/data/types';
import { NotifyService } from '@core/shared/services';
import { IScheduleReport } from '@core/modules/admin/data/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AdminReportsFacade {
  private readonly reportService = inject(AdminReportsService);
  private readonly notify = inject(NotifyService);

  getInitReportsData(): Observable<IResponseMap<IReportFiltersInitData>> {
    return this.reportService.reportInitData().pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
    );
  }

  consultScheduleReport(
    filters: TScheduleReportFilters,
  ): Observable<IResponseMap<IScheduleReport[]>> {
    return this.reportService.getScheduleReportData(filters).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
      tap((resp) => {
        if (!resp.success) {
          this.notify.error('Espera!', (resp as any).message);
        }
      }),
    );
  }
}
