/* eslint-disable no-empty-function */
import { DateTime } from 'luxon';

import {
  TAppointment,
  TAvailableCapability,
  TimeSlotsInfo,
  TScheduleActive,
  TSlot,
  TValidatedAccountContract,
} from '../types';
import { DateTimeAdapter } from '@core/domain/adapters';
import { TScheduledEntityCreated } from '@core/shared/types';

export class ErrorAccount {
  private constructor(
    readonly message: string,
    readonly status: 'SUCCESS' | 'ERROR',
    readonly errorCode?: number,
  ) {}

  static fromJson(contract: Partial<TValidatedAccountContract>) {
    return new ErrorAccount(
      contract?.message || '',
      contract.status || 'ERROR',
      contract.errorCode || 0,
    );
  }

  get hasError() {
    return this.status === 'ERROR';
  }
}

export class AccountInfo {
  private constructor(
    readonly userName: string,
    readonly address: string,
    readonly phone: string,
    readonly account: string,
    readonly city: string,
    readonly hasVantiMax: boolean,
  ) {}

  static fromJson(appointment: Partial<TAppointment>) {
    return new AccountInfo(
      appointment?.ownAccount || '',
      appointment?.address || '',
      appointment?.phone || '',
      appointment?.account || '',
      appointment?.city || '',
      appointment?.vantimax_coverage || false,
    );
  }

  get accountNumber() {
    return this.account.replace('0000', '');
  }
}

export class ScheduleActiveEntity {
  private constructor(
    readonly id: string,
    readonly scheduleDate: DateTime,
    readonly typeService: string,
    readonly scheduleTime: string,
  ) {}

  static fromJson(account: Partial<TScheduleActive>) {
    return new ScheduleActiveEntity(
      account?.id || '',
      account?.schedule_date
        ? DateTimeAdapter.fromISO(account.schedule_date?.toString())
        : null,
      account?.type_service || '',
      account?.schedule_time || '',
    );
  }
}

export class AvailableCapabilityEntity {
  private constructor(
    readonly date: DateTime,
    readonly totalCapacity: number,
    readonly isActive: boolean,
    readonly timeSlotsInfo: TimeSlotsInfo[],
  ) {}

  static fromJson(available: Partial<TAvailableCapability>) {
    return new AvailableCapabilityEntity(
      available.date
        ? DateTimeAdapter.fromISO(available?.date?.toString())
        : null,
      available?.total_capacity || 0,
      available?.isActive || false,
      available?.time_slots_info?.map((timeslot) => ({
        capacity: timeslot?.capacity || 0,
        slot: timeslot?.slot || 'AM',
        label: timeslot?.label || '',
      })) || [],
    );
  }
}

export function scheduleActiveListEntity(schedule: Partial<TScheduleActive>[]) {
  return schedule?.map((item) => ScheduleActiveEntity.fromJson(item)) || [];
}

export function availableCapacityListEntity(
  available: Partial<TAvailableCapability>[],
) {
  return (
    available?.map((item) => AvailableCapabilityEntity.fromJson(item)) || []
  );
}

export class SearchAccountResponseEntity {
  private constructor(
    readonly errorAccount: ErrorAccount,
    readonly accountInfo: AccountInfo,
    readonly scheduleActive: ScheduleActiveEntity[],
    readonly availableCapabilities: AvailableCapabilityEntity[],
  ) {}

  static fromJson(contract: TValidatedAccountContract) {
    return new SearchAccountResponseEntity(
      ErrorAccount.fromJson(contract),
      AccountInfo.fromJson(contract?.data),
      scheduleActiveListEntity(contract?.data?.scheduleActive),
      availableCapacityListEntity(contract?.data?.availableCapabilities),
    );
  }
}

export class CreateAccountScheduleEntity {
  constructor(
    readonly accountInfo: AccountInfo,
    readonly scheduleActive: ScheduleActiveEntity[],
  ) {}

  static fromJson(schedule: Partial<TScheduledEntityCreated>) {
    return new CreateAccountScheduleEntity(
      AccountInfo.fromJson({
        account: schedule?.account_contract,
        address: schedule?.address_account,
        city: schedule?.city,
        ownAccount: schedule?.owner_account,
        phone: schedule?.attendant_phone,
        vantimax_coverage: schedule?.coverage_vantimax,
        scheduleActive: [
          {
            id: schedule?.id,
            schedule_date: schedule?.schedule_date,
            type_service: schedule?.service_type?.service_type,
            schedule_time: schedule?.time_slot as TSlot,
          },
        ],
      }),
      scheduleActiveListEntity([
        {
          schedule_date: schedule?.schedule_date,
          type_service: schedule?.service_type?.service_type,
          schedule_time: schedule?.time_slot as TSlot,
        },
      ]),
    );
  }
}
