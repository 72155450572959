import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { getPagePath } from '@core/domain/helpers';
import { RouteAutorizedEntity } from '@core/domain/entities';
import { UserStore } from '@core/stores';

export const authorizedGuard: CanActivateFn = (_, state) => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  const allowPath = state.url;

  const { permissionsMap, validateRoute, getRouteSplit } = RouteAutorizedEntity(
    userStore.permissions(),
  );
  const [path] = getRouteSplit(allowPath);

  if (!permissionsMap.size) {
    router.navigate(['login'], { replaceUrl: true });
    return false;
  }

  if (!validateRoute(allowPath)) {
    return false;
  }

  if (!permissionsMap.get(path)?.length) {
    const [firstPath] = Array.from(permissionsMap.keys());
    router.navigate([getPagePath(firstPath)]);

    return false;
  }

  return true;
};
