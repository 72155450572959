import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take, map } from 'rxjs';

import {
  GET_CAPABILITIES_BAGS_BY_DATE,
  GET_CAPABILITIES_BY_DATE,
  GET_FILTERS_DATA,
  GET_INIT_FILTERS_DATA,
  GET_INIT_FILTERS_DETAILS_DATA,
  UPDATE_CAPABILITIES_BY_DAY,
  UPDATE_SEGMENTED_ZONES_BAGS,
  UPDATE_SEGMENTED_ZONES_BY_DAY,
} from '../data/gql';
import {
  ICapabilitiesFilters,
  ICapabilitiesByDayInitData,
  ICapabilityMonth,
  IFiltersInitData,
  TUpdateCapabilitiesByDay,
  TUpdateSegmentedZonesByDay,
  TUpdateBags,
  ICapabilitiesByDateData,
  ICapabilitySegmentedBags,
} from '../data/types';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  private readonly apolloClient = inject(Apollo);

  getFiltersData(filters: ICapabilitiesFilters) {
    return this.apolloClient
      .query<IFiltersInitData>({
        query: GET_INIT_FILTERS_DATA,
        variables: { filters },
      })
      .pipe(
        take(1),
        map((resp) => resp.data),
      );
  }

  getFiltersChartData(filters: ICapabilitiesFilters) {
    return this.apolloClient
      .query<{ capabilitiesByMonth: ICapabilityMonth[] }>({
        query: GET_FILTERS_DATA,
        variables: { filters },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data),
      );
  }

  getCapabilitiesViewDataByZoneId(
    zoneId: string,
    filters: ICapabilitiesFilters,
  ) {
    return this.apolloClient
      .query<ICapabilitiesByDayInitData>({
        query: GET_INIT_FILTERS_DETAILS_DATA,
        variables: {
          pagination: {
            limit: 10,
            page: 1,
          },
          segmentedZonesFilters: {
            zoneId,
          },
          filters,
          filtersBags: filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }

  getCapabilitiesByDay(filters: ICapabilitiesFilters) {
    return this.apolloClient
      .query<ICapabilitiesByDateData>({
        query: GET_CAPABILITIES_BY_DATE,
        variables: {
          filters,
          filtersBags: filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }

  getCapabilitiesBagsByDate(filters: ICapabilitiesFilters) {
    return this.apolloClient
      .query<{ capabilitiesSegmentedBags: ICapabilitySegmentedBags[] }>({
        query: GET_CAPABILITIES_BAGS_BY_DATE,
        variables: {
          filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }

  updateCapabilitiesByDay(data: TUpdateCapabilitiesByDay) {
    return this.apolloClient
      .mutate<string>({
        mutation: UPDATE_CAPABILITIES_BY_DAY,
        variables: {
          data,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }

  updateSegmentedZonesByDay(data: TUpdateSegmentedZonesByDay) {
    return this.apolloClient
      .mutate<string>({
        mutation: UPDATE_SEGMENTED_ZONES_BY_DAY,
        variables: {
          data,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }

  updateSegmentedZonesBags(data: TUpdateBags) {
    return this.apolloClient
      .mutate<string>({
        mutation: UPDATE_SEGMENTED_ZONES_BAGS,
        variables: {
          data,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }
}
