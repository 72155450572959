import { toTitleCase } from '@core/domain/helpers';
import { ICapabilitySegmentedBags, TCapabilityType } from '../types';

export const mapCapabilityBags = (bags: Partial<ICapabilitySegmentedBags>[]) =>
  bags.map((bag) => CapabilityWithBags.fromJson(bag));

export class CapabilityWithBags {
  constructor(
    readonly zoneId: string,
    readonly zoneName: string,
    readonly segmentedZoneId: string | null,
    readonly segmentedZoneName: string | null,
    readonly initialCapability: number,
    readonly percentage: number,
    readonly scheduled: number,
    readonly available: number,
    readonly percentageAm: number,
    readonly percentagePm: number,
    readonly availableAm: number,
    readonly availablePm: number,
    readonly operationLabel: string,
    readonly active: boolean,
    readonly capabilityType: TCapabilityType,
    readonly bagId: string,
    readonly bagName: string,
  ) {}

  static fromJson(bag: Partial<ICapabilitySegmentedBags>) {
    return new CapabilityWithBags(
      bag?.zone_id ?? '',
      toTitleCase(bag?.zone_name ?? ''),
      bag?.segmented_zone_id || 'no_segmented',
      toTitleCase(bag?.segmented_zone_name || 'Sin asignación'),
      bag?.initial_capacity ?? 0,
      bag?.percentage ?? 0,
      bag?.total_scheduled ?? 0,
      bag?.total_available ?? 0,
      bag?.percentage_am ?? 0,
      bag?.percentage_pm ?? 0,
      bag?.available_am ?? 0,
      bag?.available_pm ?? 0,
      bag?.operation_label ?? '',
      bag?.active ?? false,
      bag?.capability_type ?? 'FILIAL',
      bag?.bag?.id ?? '',
      bag?.bag?.name ?? '',
    );
  }
}
