import { Component, inject, OnInit, signal } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  RecaptchaModule,
  RecaptchaV3Module,
  ReCaptchaV3Service,
} from 'ng-recaptcha';

import { NotifyService } from '@core/shared/services';
import { AuthBlocService } from '@core/blocs';
import { TRole } from '@core/domain/types';
import { environment } from '@env/environment';
import { SmSelectComponent } from '@core/shared/components';

@Component({
  selector: 'page-login',
  standalone: true,
  imports: [
    SmSelectComponent,
    RecaptchaModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.styles.css',
})
export class LoginComponent implements OnInit {
  private readonly authBloc = inject(AuthBlocService);
  private readonly recaptchaService = inject(ReCaptchaV3Service);
  private readonly notify = inject(NotifyService);
  public readonly role = new FormControl<TRole>('' as TRole);
  public readonly isFetching = signal(false);

  public readonly options = environment.roleSelector;

  ngOnInit() {
    localStorage.clear();
  }

  get canShowRoleSelector() {
    return !!this.options?.length;
  }

  getReCaptchaToken() {
    return this.recaptchaService.execute('init_form_signin').pipe(
      map((resp) => ({ success: true, data: resp })),
      catchError((err) => of({ success: false, message: err.message })),
    );
  }

  onSignIn() {
    this.isFetching.set(true);
    return this.getReCaptchaToken()
      .pipe(
        switchMap(() => {
          return this.authBloc.getUrlAuth(this.role.value);
        }),
      )
      .subscribe({
        next: ({ success, message }) => {
          if (!success) {
            this.notify.error('Espera', message!);
          }
        },
        complete: () => this.isFetching.set(false),
      });
  }
}
