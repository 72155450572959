import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class SmDialogService {
  private readonly dialog = inject(MatDialog);

  openDialog(
    component: ComponentType<unknown>,
    data?: any,
    disableClose = true,
  ) {
    return this.dialog.open(component, {
      backdropClass: 'bg-blur',
      panelClass: 'dialog-round',
      data,
      disableClose,
    });
  }
}
