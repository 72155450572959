<section class="flex bg-white xs:w-full lg:max-w-screen-lg xl:max-w-screen-xl xxl:max-w-screen-xxl xs:p-4 lg:pl-14 xl:p-5 xl:pl-14 xs:justify-start md:justify-between w-full">
  <div class="flex gap-2 items-center">
    <picture class="w-5">
      <img src="assets/images/vanti-icon.png" alt="vanti icon">
    </picture>
    <h1 class="font-extrabold xs:text-lg md:text-xl">{{ title }}</h1>
  </div>
  <div class="gap-3 items-center xs:hidden md:flex">
    <div class="flex flex-col items-end">
      <p class="font-bold">{{ user.name }}</p>
      <p class="text-sm font-normal">{{ user.role }}</p>
    </div>
    <div class="w-12 h-12 rounded-full flex items-center justify-center bg-neutral-60">
      <span class="font-bold">{{ userShortName() }}</span>
    </div>
  </div>
</section>
